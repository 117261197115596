import _ from 'lodash';
import actions from '../actions/full.form';
import api_actions from '../actions/api';
import redirect from '../../utils/redirect';

const updateUuidObject = (_state, action, new_data, _uuid = null) => {
    const state = { ..._state };
    const uuid = _.get(action, 'payload.data.uuid', '') || _uuid;

    if (!uuid) {
        return state;
    }
    if (!state[uuid]) {
        state[uuid] = {};
    }

    state[uuid] = {
        ...state[uuid],
        ...new_data
    };

    return state;
};

const actionHandlers = {
    [actions.form_get_data]: (state, action) => {
        const { uuid } = action;
        switch (action.payload) {
            case api_actions.api_start:
                return updateUuidObject(state, action, {
                    isLoading: true,
                    errors: {}
                }, uuid);
            case api_actions.api_end:
                return updateUuidObject(state, action, { isLoading: false }, uuid);
            default:
                return state;
        }
    },
    [actions.form_succeeded]: (state, action, name) => {
        const { uuid, state: lead_state, quote_uuid, lead = {}, defaults, schema = {}, policy_uuid = 'test', message = '', copy = false, policy_type = '', display_quote_details = false } = action.payload;
        const _uuid = uuid || action.uuid;
        const _state = state[uuid] ? state[uuid] : {};
        const redirect_path = _uuid ? (policy_uuid ? `/agent/${name.replace(/_/g, '-')}/${_uuid}/${policy_uuid}/${policy_type}` : `/agent/${name.replace(/_/g, '-')}/${_uuid}`) : '';

        return {
            ...updateUuidObject(state, action, {
                data: {
                    ...state.data,
                    ...lead
                },
                isLoading: false,
                lead_state,
                quote_uuid,
                defaults: {
                    ..._state.defaults,
                    ...defaults
                },
                message,
                schema: {
                    ..._state.schema,
                    ...schema
                },
                copy,
                display_quote_details
            }, _uuid),
            ...redirect.add(redirect_path),
        };
    },
    [actions.form_failed]: (state, action) => {
        const { uuid, values } = action;
        const errors = _.get(action, 'payload.data.errors');
        const _values = _.cloneDeep(values);

        return updateUuidObject(state, action, {
            data: { ..._values },
            errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message') || 'Please try later',
            isLoading: false,
        }, uuid);
    },
    [actions.vrm_search]: (state, action) => {
        const { uuid, key } = action;
        const _state = _.get(state, uuid, {});

        switch (action.payload) {
            case api_actions.api_start:
                return updateUuidObject(state, action, {
                    vrmIsLoading: {
                        ..._state.vrmIsLoading,
                        [key]: true
                    },
                    vrmErrors: {
                        ..._state.vrmErrors,
                        [key]: {}
                    }
                }, uuid);
            case api_actions.api_end:
                return updateUuidObject(state, action, {
                    vrmIsLoading: {
                        ..._state.vrmIsLoading,
                        [key]: false
                    }
                }, uuid);
            default:
                return state;
        }
    },
    [actions.vrm_failed]: (state, action) => {
        const { uuid, key } = action;
        const _errors = _.get(action, 'payload.data.errors');

        let errors = { [key]: !_.isObject(_errors) ? _errors : _.values(_errors)[0] };
        errors = !_.isEmpty(errors) ? errors : 'Our vehicle lookup service is down, please enter manually';

        const _state = _.get(state, uuid, {});

        return updateUuidObject(state, action, {
            vrmErrors: {
                ..._state.vrmErrors,
                [key]: errors
            }
        }, uuid);
    },
    [actions.vrm_succeeded]: (state, action) => {
        const { uuid, key } = action;
        const _state = _.get(state, uuid, {});

        return updateUuidObject(state, action, {
            vrmData: {
                ..._state.vrmData,
                [key]: action.payload
            }
        }, uuid);
    },
    [actions.postcode_search]: (state, action) => {
        const { uuid, key } = action;
        const _state = _.get(state, uuid, {});

        switch (action.payload) {
            case api_actions.api_start:
                return updateUuidObject(state, action, {
                    postcodeIsLoading: {
                        ..._state.postcodeIsLoading,
                        [key]: true
                    },
                    postcodeErrors: {
                        ..._state.postcodeErrors,
                        [key]: {}
                    },
                    postcodeData: {
                        ..._state.postcodeData,
                        [key]: {}
                    },
                }, uuid);
            case api_actions.api_end:
                return updateUuidObject(state, action, {
                    postcodeIsLoading: {
                        ..._state.postcodeIsLoading,
                        [key]: false
                    }
                }, uuid);
            default:
                return state;
        }
    },
    [actions.postcode_select]: (state, action) => {
        const { uuid, key } = action;
        const _state = _.get(state, uuid, {});
        switch (action.payload) {
            case api_actions.api_start:
                return updateUuidObject(state, action, {
                    postcodeData: {
                        ..._state.postcodeData,
                        [key]: {
                            ..._state.postcodeData[key],
                            address: {}
                        }
                    },
                }, uuid);
            case api_actions.api_end:
                return updateUuidObject(state, action, {}, uuid);
            default:
                return state;
        }
    },
    [actions.postcode_search_succeeded]: (state, action) => {
        const { uuid, key } = action;
        const mappedPostcodes = action.payload.map((p) => {
            const { StreetAddress, Id } = p;

            return {
                key: Id,
                value: StreetAddress
            };
        });

        const _state = _.get(state, uuid, {});

        return updateUuidObject(state, action, {
            postcodeData: {
                ..._state.postcodeData,
                [key]: {
                    ..._state.postcodeData[key],
                    mappedPostcodes
                }
            }
        }, uuid);
    },
    [actions.postcode_select_succeeded]: (state, action) => {
        const { uuid, key } = action;
        const { Line1, Line2, Line3, PrimaryStreet, County, Postcode, PostTown, SubBuilding, BuildingName, BuildingNumber } = _.pick(
            action.payload[0],
            [
                'Line1',
                'Line2',
                'Line3',
                'PrimaryStreet',
                'County',
                'Postcode',
                'PostTown',
                'SubBuilding',
                'BuildingName',
                'BuildingNumber'
            ]
        );

        const street = [
            SubBuilding,
            BuildingName,
            BuildingNumber,
            PrimaryStreet
        ].join(' ').trim();

        const _state = _.get(state, uuid, {});
        const address = {
            street: [
                Line1,
                Line2,
                Line3
            ].join(' ').trim() || street,
            city: PostTown,
        };

        return updateUuidObject(state, action, {
            postcodeData: {
                ..._state.postcodeData,
                [key]: {
                    ..._state.postcodeData[key],
                    address
                }
            }
        }, uuid);
    },
    [actions.postcode_failed]: (state, action) => {
        const { uuid, key } = action;
        const _errors = _.get(action, 'payload.data.errors');

        let errors = { [key]: !_.isObject(_errors) ? _errors : _.values(_errors)[0] };
        errors = !_.isEmpty(errors) ? errors : 'Our address lookup service is down, please enter manually';
        const _state = _.get(state, uuid, {});

        return updateUuidObject(state, action, {
            postcodeErrors: {
                ..._state.postcodeErrors,
                ...errors
            }
        }, uuid);
    },
    [actions.add]: (state, action) => {
        const { fields, uuid, formValues } = action.payload;
        let _values = _.cloneDeep(formValues);
        if (!uuid || !fields) {
            return state;
        }


        const defaultValues = _.get(state, `${uuid}.defaults.defaults`, {});

        fields.forEach((f) => {
            const defaultKey = f.replace(/\d+/g, '0');
            const defaultValue = _.get(defaultValues, defaultKey, '');
            _.set(_values, f, defaultValue);
        });

        if (fields[0].match(/^driver.\d+/)) {
            const defaultOccupation = _.get(defaultValues, 'occupation', []);
            _values = {
                ..._values,
                occupation: [
                    ..._values.occupation,
                    ...defaultOccupation
                ],
            };
        }

        return updateUuidObject(state, action, {
            data: {
                ...formValues,
                ..._values
            }
        }, uuid);
    },
    [actions.remove]: (state, action) => {
        const { fields, uuid, formValues } = action.payload,
            _values = _.cloneDeep(formValues),
            { 1: main, 2: mainIndex, 3: partial, 4: partialIndex } = fields[0].match(/^([\w_]+)\.(\d{1,2})\.([\w_]+)\.?(\d{1,2})?\.?[\w_]+?$/);

        if (!uuid || !fields) {
            return state;
        }

        const path = partialIndex ? main.concat('.', mainIndex, '.', partial) : main;
        const index = partialIndex ? partialIndex : mainIndex;

        const refs = _.get(_values, path);
        refs.splice(index, 1);
        _.set(_values, path, refs);

        if (fields[0].match(/^driver.\d+/)) {
            // remove everything related to the removed driver
            const driver_id = fields[0].replace(/^driver\.(\d+)\..*$/, '$1');
            _.forEach(_values, (val, key) => {
                if (_.isArray(val)) {
                    _values[key] = val.filter((f) => !f.hasOwnProperty('driver_id') || f.driver_id !== `driver_${driver_id}`);
                }
            });
        }

        return updateUuidObject(state, action, { data: { ..._values } }, uuid);
    },
    //company
    [actions.company_search]: (state, action) => {
        const { uuid, key } = action;
        const _state = _.get(state, uuid, {});

        switch (action.payload) {
            case api_actions.api_start:
                return updateUuidObject(state, action, {
                    companyIsLoading: {
                        ..._state.companyIsLoading,
                        [key]: true
                    },
                    companyData: {
                        ..._state.companyData,
                        [key]: {}
                    },
                    companyErrors: {
                        ..._state.companyErrors,
                        [key]: {}
                    }
                }, uuid);
            case api_actions.api_end:
                return updateUuidObject(state, action, {
                    companyIsLoading: {
                        ..._state.companyIsLoading,
                        [key]: false
                    }
                }, uuid);
            default:
                return state;
        }
    },
    [actions.company_search_failed]: (state, action) => {
        const { uuid, key } = action;
        const _errors = _.get(action, 'payload.data.errors');

        let errors = { [key]: !_.isObject(_errors) ? _errors : _.values(_errors)[0] };
        errors = !_.isEmpty(errors[key]) ? errors : _.get(action, 'payload.data.message') || 'Our company lookup service is down, please try later';

        const _state = _.get(state, uuid, {});

        return updateUuidObject(state, action, {
            companyErrors: {
                ..._state.companyErrors,
                [key]: errors
            }
        }, uuid);
    },
    [actions.company_search_succeeded]: (state, action) => {
        const { uuid, key } = action;
        const _state = _.get(state, uuid, {});

        return updateUuidObject(state, action, {
            companyData: {
                ..._state.companyData,
                [key]: action.payload
            }
        }, uuid);
    },

};

export function getDefaults() {
    return {};
}

const formReducer = (formName = '') => function form(state = getDefaults(), action) {
    const { name } = action;

    if (name !== formName) {
        return state;
    }

    if (actionHandlers[action.type]) {
        return actionHandlers[action.type](state, action, name);
    }

    return state;
};


export const handleAddressLookupSearch = (state, action) => {
    //clear address lookup error from full form reducer, key example: driver.0.address.postcode
    const { key, name, uuid } = action;

    return {
        ...state,
        [name]: {
            ...state[name],
            [uuid]: {
                ...state[name][uuid],
                errors: {
                    ..._.omit(state[name][uuid].errors, [key]),
                },
            },
        },
    };
};
export default formReducer;
