import _ from 'lodash';

export const SECTIONS = {
    PRODUCTS: 'products',
    MANUAL_PRODUCTS: 'manual_products',
    QUOTES: 'quotes',
    RATES: 'rates',
    POLICIES: 'policies',
};

export const ROLES = {
    ADMIN: 'admin',
    USER: 'user',
};

/*
 user: {
   email,
   isSuperAdmin,
   roles: {
      isOverallAdmin,
      isOverallUser,
      sections: {
         name: {name, role}
      }
   }
 }
 */

const isSuperAdmin = (user) => _.get(user, 'isSuperAdmin', false);

const isOverallAdmin = (user) => _.get(user, 'roles.isOverallAdmin', false);

const isOverallUser = (user) => _.get(user, 'roles.isOverallUser', false);

export const canAccess = (user, role, section) => isSuperAdmin(user) ||
    isOverallAdmin(user) ||
    (role === 'user' && isOverallUser(user)) ||
    _.get(user, `roles.sections.${section}.role`) === role;

export const isAuthorized = user => {
    const access_token = _.get(user, 'access_token', null);
    //todo check token expired
    return !!(user && access_token && access_token.length);
};
