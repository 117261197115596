import React, {Suspense} from 'react';
import './app.scss';
import _ from 'lodash';
import {Route, Switch, Redirect} from 'react-router-dom';
import products from './utils/products';
import Loader from './components/widgets/loader';
import requiredAuth from './components/admin/page.authorised';

const Admin = React.lazy(() => import('./containers/admin'));
const FullForm = React.lazy(() => import ('./containers/full.form'));
const Quotes = React.lazy(() => import ('./containers/quote'));
const Details = React.lazy(() => import('./containers/details'));
const PolicyAwaitingList = React.lazy(() => import("./containers/policy.awaiting"));
const Policy = React.lazy(() => import('./containers/policy'));
const Default = React.lazy(() => import( './components/default'));

const fullFormRoutes = () => _.map(products, (v, k) => <Route exact key={v} path={`/agent/${v.replace(/_/g, '-')}/:uuid?/:policy_uuid?/:policy_type?`} component={requiredAuth(FullForm, {product: v})}/>);


function App() {
    return (
        <div className="App">
            <Suspense fallback={<Loader message={'Loading...'}/>}>
                <Switch>
                    {/*todo header*/}
                    {/*full form routes*/}
                    <Route exact path="/">
                        <Redirect to="/admin"/>
                    </Route>
                    {/* Admin Pages */}
                    <Route exact path='/admin' component={requiredAuth(Admin)}/>

                    {/*full form routes - agent routes*/}
                    {fullFormRoutes()}

                    <Route path='/agent/quote/details/:uuid' component={requiredAuth(Details)}/>
                    <Route path='/agent/quote/:uuid?' component={requiredAuth(Quotes)}/>

                    <Route path='/agent/policy/awaiting' component={requiredAuth(PolicyAwaitingList)}/>
                    <Route path='/agent/policy/details/:uuid' component={requiredAuth(Details)}/>
                    <Route path='/agent/policy/live' component={requiredAuth(Policy)}/>
                    <Route path='/agent/policy/cancelled' component={requiredAuth(Policy)}/>
                    <Route path='/agent/policy/lapsed' component={requiredAuth(Policy)}/>

                    {/*todo footer*/}
                    <Route component={Default}/>
                </Switch>
            </Suspense>
        </div>
    );
}

export default App;
