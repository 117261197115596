import _ from 'lodash';
import redirect from '../../utils/redirect';

export const handleCopySucceeded = (state, action) => {
    const {uuid, product, state: lead_state, lead: data, ...rest} = action.payload;
    const {isPolicy} = action;

    const productState = {
        [product]: {
            ...state[product],
            [uuid]: {
                ...rest,
                isLoading: false,
                data,
                product,
                uuid,
                lead_state,
                copy: true
            },
        },
    };

    if (isPolicy) {
        return {
            ...state,
            ...productState,
            policy: {
                ...state.policy,
                ...redirect.add(`/agent/${product.replace(/_/g, '-')}/${uuid}`)
            }
        };
    }

    return {
        ...state,
        ...productState,
        quote: {
            ...state.quote,
            copy: true,
            ...redirect.add(`/agent/${product.replace(/_/g, '-')}/${uuid}`)
        }
    };
};

export const handleCopyFailed = (state, action) => {
    const {isPolicy} = action;
    const errors = _.get(action, 'payload.data.errors', 'Error trying getting the lead');
    if (isPolicy) {
        return {
            ...state,
            policy: {
                ...state.policy,
                errors
            }
        };
    }

    return {
        ...state,
        quote: {
            ...state.quote,
            errors
        }
    };
};


