const PRODUCT_FASTFOOD = 'fastfood';
const PRODUCT_BUSINESS_CAR = 'business_car';
const PRODUCT_BC_ERS_ROLLOVER = 'bc_ers_rollover';
const PRODUCT_MANUAL = 'manual_product';
const PRODUCT_VAN = 'van';

export const formProducts = {
    PRODUCT_FASTFOOD,
    PRODUCT_BUSINESS_CAR,
    PRODUCT_BC_ERS_ROLLOVER,
    PRODUCT_VAN
};

const products = {
    ...formProducts,
    PRODUCT_MANUAL
};

export const mapProducts = (product) => {
    const _products = {
        [products.PRODUCT_FASTFOOD]: 'fast food',
        [products.PRODUCT_BUSINESS_CAR]: 'business car',
        [products.PRODUCT_BC_ERS_ROLLOVER]: 'bc ers rollover',
        [products.PRODUCT_MANUAL]: 'manual product',
        [products.PRODUCT_VAN]: 'van'
    };

    return _products[product] ? _products[product] : product;
};

export default products;
