import {handleActions} from 'redux-actions';
import actions from '../actions/vehicle.lookup';
import api_actions from '../actions/api';
import _ from 'lodash';

export function getDefaults () {
    return {
        errors: {},
        makes: [],
        models: [],
        years: [],
        bodyTypes: [],
        transmissions: [],
        fuelTypes: [],
        rawSpecs: [],
        specs: [],
        isLoading: false,
        details: {}
    };
}


export default handleActions({
    [actions.vehicle_lookup_make]: (state, action) => {
        switch (action.payload) {
        case api_actions.api_start:
            return {
                ...state,
                errors: {},
                makes: [],
                models: [],
                years: [],
                bodyTypes: [],
                transmissions: [],
                fuelTypes: [],
                rawSpecs: [],
                specs: [],
                isLoading: true,
                details: {}
            };
        case api_actions.api_end:
            return {...state,
                isLoading: false};
        default:
            return state;
        }
    },

    [actions.vehicle_lookup_model]: (state, action) => {
        switch (action.payload) {
        case api_actions.api_start:
            return {
                ...state,
                errors: {},
                models: [],
                years: [],
                bodyTypes: [],
                transmissions: [],
                fuelTypes: [],
                rawSpecs: [],
                specs: [],
                isLoading: true,
                details: {}
            };
        case api_actions.api_end:
            return {...state,
                isLoading: false};
        default:
            return state;
        }
    },

    [actions.vehicle_lookup_year]: (state, action) => {
        switch (action.payload) {
        case api_actions.api_start:
            return {
                ...state,
                errors: {},
                years: [],
                bodyTypes: [],
                transmissions: [],
                fuelTypes: [],
                rawSpecs: [],
                specs: [],
                isLoading: true,
                details: {}
            };
        case api_actions.api_end:
            return {...state,
                isLoading: false};
        default:
            return state;
        }
    },

    [actions.vehicle_lookup_body_type]: (state, action) => {
        switch (action.payload) {
        case api_actions.api_start:
            return {
                ...state,
                errors: {},
                bodyTypes: [],
                transmissions: [],
                fuelTypes: [],
                rawSpecs: [],
                specs: [],
                isLoading: true,
                details: {}
            };
        case api_actions.api_end:
            return {...state,
                isLoading: false};
        default:
            return state;
        }
    },
    [actions.vehicle_lookup_transmission]: (state, action) => {
        switch (action.payload) {
        case api_actions.api_start:
            return {
                ...state,
                errors: {},
                transmissions: [],
                fuelTypes: [],
                rawSpecs: [],
                specs: [],
                isLoading: true,
                details: {}
            };
        case api_actions.api_end:
            return {...state,
                isLoading: false};
        default:
            return state;
        }
    },

    [actions.vehicle_lookup_fuel_type]: (state, action) => {
        switch (action.payload) {
        case api_actions.api_start:
            return {
                ...state,
                errors: {},
                fuelTypes: [],
                rawSpecs: [],
                specs: [],
                isLoading: true,
                details: {}
            };
        case api_actions.api_end:
            return {...state,
                isLoading: false};
        default:
            return state;
        }
    },
    [actions.vehicle_lookup_spec]: (state, action) => {
        switch (action.payload) {
        case api_actions.api_start:
            return {
                ...state,
                errors: {},
                rawSpecs: [],
                specs: [],
                isLoading: true,
                details: {}
            };
        case api_actions.api_end:
            return {...state,
                isLoading: false};
        default:
            return state;
        }
    },


    [actions.vehicle_lookup_failed]: (state, action) => {
        const errors = _.get(action, 'payload.data.errors');

        return {...state,
            errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message')};
    },
    [actions.vehicle_lookup_succeeded]: (state, action) => ({...state,
        ...action.payload})
}, getDefaults());


const mapVehicleDetails = (data) => {
    const {make, model, year, cc: engine, gr50: abi_group, fuelType: fuel_type, transmission, seats, abi_code: abi, bodyType: body_type} = _.pick(data, [
        'make',
        'model',
        'year',
        'cc',
        'gr50',
        'fuelType',
        'transmission',
        'seats',
        'abi_code',
        'bodyType',
    ]);

    return {
        make: make.toLowerCase(),
        model: model.toLowerCase(),
        year,
        engine,
        abi_group,
        fuel_type: fuel_type.toLowerCase(),
        transmission: transmission.toLowerCase(),
        seats,
        abi,
        body_type,
    };
};
export const handleUpdateVehicleDetailsManually = (state, action) => {
    const {uuid, product, formValues, vehicleId, ...rest} = action.payload;

    if (!uuid || !product || vehicleId < 0 || _.isEmpty(formValues)) {
        return state;
    }


    const vehicle = formValues.vehicle.map((v, i) => {
        if (i === vehicleId) {
            return {...v,
                ...mapVehicleDetails(rest)};
        }

        return v;
    });

    return {
        ...state,
        [product]: {
            ...state[product],
            [uuid]: {
                ...state[product][uuid],
                data: {
                    ...state[product][uuid].data,
                    ...formValues,
                    vehicle: [...vehicle]
                }
            }
        }
    };
};
