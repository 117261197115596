import {createAction} from 'redux-actions';
import {apiPost} from './api';

const actions = {
    login: createAction('ADMIN_LOGIN'),
    login_failed: createAction('ADMIN_LOGIN_FAILED'),
    login_succeeded: createAction('ADMIN_LOGIN_SUCCEEDED'),

    logout: createAction('ADMIN_LOGOUT'),

    navigate: createAction('ADMIN_NAVIGATE')
};
export default actions;

export const login = (data) => apiPost('admin/login', data, actions.login, actions.login_succeeded, actions.login_failed);
