import {createAction} from 'redux-actions';
import {apiGet} from './api';

const actions = {
    copy: createAction('COPY_GET'),
    copy_succeeded: createAction('COPY_SUCCEEDED'),
    copy_failed: createAction('COPY_FAILED'),
};

export default actions;
const copySucceeded = (response, isPolicy) => ({
    type: actions.copy_succeeded.toString(),
    isPolicy,
    payload: response
});
const copyFailed = (response, isPolicy) => ({
    type: actions.copy_failed.toString(),
    isPolicy,
    payload: response
});

export const copy = (data, isPolicy = false) => apiGet('form/copy', data, actions.copy, (res) => copySucceeded(res, isPolicy), (res) => copyFailed(res, isPolicy));
