import {handleActions} from 'redux-actions';
import actions from '../actions/admin';
import {API_END, API_LOADING, API_READY, API_START} from '../constants/api.constants';
import _ from 'lodash';

export function getDefaults() {
  return {
    login: {
      first_name: null,
      last_name: null,
      email: null,
      access_token: null,
      success_message: '',
      roles: [],
      errors: {},
    },
    users: [],
    changes: [],
  };
}

export default handleActions({

  [actions.login]: (state, action) => {
    switch (action.payload) {
      case API_START:
        return {
          ...state,
          login: {
            ...state.login,
            errors: {},
            status: API_LOADING,
          },
        };
      case API_END:
        return {
          ...state,
          login: {
            ...state.login,
            status: API_READY,
          },
        };
      default:
        return state;
    }
  },
  [actions.login_failed]: (state, action) => {
    const errors = _.get(action, 'payload.data.errors');

    return {
      ...state,
      login: {
        ...state.login,
        errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message'),
        success_message: '',
      },
    };
  },
  [actions.login_succeeded]: (state, action) => {
    const user = _.pick(action.payload, [
          'email',
          'isSuperAdmin',
          'first_name',
          'last_name',
          'roles',
        ]),
        auth = action.payload.auth.filter((a) => a.type === 'jwt');

    if (auth.length) {
      user.access_token = auth[0].auth;
    }

    return {
      ...state,
      login: {
        ...state.login,
        ...user,
      },
    };
  },
  [actions.logout]: (state) => ({
    ...state,
    login: getDefaults().login,
  }),

  [actions.navigate]: (state) => ({
    ...state,
    login: {
      ...state.login,
      success_message: '',
    },
  }),

}, getDefaults());

