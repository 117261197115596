import {createAction} from 'redux-actions';
import {apiGet, apiPost} from './api';

const actions = {
    policy_send: createAction('POLICY_SEND'),
    policy_refresh: createAction('POLICY_REFRESH'),
    policy_succeeded: createAction('POLICY_SUCCEEDED'),
    policy_failed: createAction('POLICY_FAILED'),

    awaiting_send: createAction('POLICY_AWAITING_SEND'),
    awaiting_refresh: createAction('POLICY_AWAITING_REFRESH'),
    awaiting_succeeded: createAction('POLICY_AWAITING_SUCCEEDED'),
    awaiting_failed: createAction('POLICY_AWAITING_FAILED'),

    details_send: createAction('POLICY_DETAILS_SEND'),
    details_failed: createAction('POLICY_DETAILS_FAILED'),
    details_succeeded: createAction('POLICY_DETAILS_SUCCEEDED'),

    update_state_send: createAction('POLICY_STATE_SEND'),
    update_state_succeeded: createAction('POLICY_STATE_SEND_SUCCEEDED'),
    update_state_failed: createAction('POLICY_STATE_SEND_FAILED'),

    cancellation_balance_send: createAction('POLICY_CANCELLATION_BALANCE_SEND'),
    cancellation_balance_succeeded: createAction('POLICY_CANCELLATION_BALANCE_SEND_SUCCEEDED'),
    cancellation_balance_failed: createAction('POLICY_CANCELLATION_BALANCE_SEND_FAILED'),

    cancellation_balance_reset: createAction('POLICY_CANCELLATION_BALANCE_RESET')
};
export default actions;

export const getPolicies = (path, data) => apiGet(`policy/${path}`, data, actions.policy_send, actions.policy_succeeded, actions.policy_failed);
export const refreshPolicies = (path, data) => apiGet(`policy/${path}`, data, actions.policy_refresh, actions.policy_succeeded, actions.policy_failed);
export const getAwaitingPolicies = () => apiGet('policy/awaiting', {}, actions.awaiting_send, actions.awaiting_succeeded, actions.awaiting_failed);
export const refreshAwaitingPolicies = () => apiGet('policy/awaiting', {}, actions.policy_refresh, actions.awaiting_succeeded, actions.awaiting_failed);
export const getPolicyDetails = (uuid) => apiGet('policy/details', uuid, actions.details_send, actions.details_succeeded, actions.details_failed);

export const onGoLive = (uuid) => apiPost('policy/live', uuid, actions.update_state_send, actions.update_state_succeeded, actions.update_state_failed);
export const onCancel = (uuid) => apiPost('policy/cancel', uuid, actions.update_state_send, actions.update_state_succeeded, actions.update_state_failed);
export const onMta = (uuid) => apiPost('policy/mta', uuid, actions.update_state_send, actions.update_state_succeeded, actions.update_state_failed);
export const onAwaitingCancelledDocuments = (uuid) => apiPost('policy/awaiting-cancelled-documents', uuid, actions.update_state_send, actions.update_state_succeeded, actions.update_state_failed);
export const onCancellationBalance = (uuid) => apiPost('policy/calculate-cancellation-balance', uuid, actions.cancellation_balance_send, actions.cancellation_balance_succeeded, actions.cancellation_balance_failed);
export const onPendingCancellation = (data) => apiPost('policy/pending-cancellation', data, actions.update_state_send, actions.update_state_succeeded, actions.update_state_failed);
export const onRenewal = (uuid) => apiPost('policy/renewal', uuid, actions.update_state_send, actions.update_state_succeeded, actions.update_state_failed);

