import axios from 'axios';
import actions from '../actions/api';
import adminActions from '../actions/admin'
import {apiError, apiStart, apiEnd, apiSuccess} from '../actions/api';

const apiMiddleware = ({dispatch, getState}) => (next) => (action) => {

  if (action.type !== actions.api) {
    return next(action);
  }

  const state = getState(),
      access_token = state?.admin?.login?.access_token;

  const {
    url,
    method,
    data,
    onSuccess,
    onFailure,
    headers,
  } = action.payload;
  const {
    label,
  } = action.payload;

  if (label) {
    typeof label === 'function' ? dispatch(label(actions.api_start)) : dispatch(apiStart(label));
  }

  axios
      .request({
        url,
        method,
        headers: {
          'content-type': 'application/json',
          'Authorization': `JWT ${access_token}`,
          ...headers,
        },
        [['GET'].includes(method) ? 'params' : 'data']: data,
      })
      .then(({data}) => {
        if (label) {
          typeof label === 'function' ? dispatch(label(actions.api_success)) : dispatch(apiSuccess(label));
        }
        dispatch(onSuccess(data));
      })
      .catch(({response}) => {
        if (label) {
          typeof label === 'function' ? dispatch(label(actions.api_error)) : dispatch(apiError(label));
        }

        if (response?.status === 401) {
          dispatch(adminActions.logout())
        }

        dispatch(onFailure(response));
      })
      .finally(() => {
        if (label) {
          typeof label === 'function' ? dispatch(label(actions.api_end)) : dispatch(apiEnd(label));
        }
      });
};

export default apiMiddleware;
