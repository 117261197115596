import {createAction} from 'redux-actions';
import {apiGet} from './api';

const actions = {
    lists_get: createAction('LISTS_GET'),
    lists_succeeded: createAction('LISTS_SUCCEEDED'),
    lists_failed: createAction('LISTS_FAILED'),
};

export default actions;

export const getLists = (list = '', keys = false) => apiGet('api/lists', {list,
    keys}, actions.lists_get, (lists) => actions.lists_succeeded({
    list_name: list,
    lists
}), actions.lists_failed);
