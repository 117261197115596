import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import * as serviceWorker from './serviceWorker';
import {HashRouter as Router} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

//redux
import {Provider} from 'react-redux';
import {store, persistor, clearStorage} from './redux/configure.store';
//redux persist
import {PersistGate} from 'redux-persist/integration/react';

const reset = () => {
    persistor
        .purge()
        .then(() => persistor.flush())
        .then(() => {
            setTimeout(() => window.location.reload(), 100);
        });
};

ReactDOM.render(
      <Provider store={store}>
          <Router>
              <PersistGate loading={null} onBeforeLift={clearStorage} persistor={persistor}>
                  <App/>
              </PersistGate>
          </Router>
      </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
