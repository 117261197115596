import { createAction } from 'redux-actions';
import { apiGet, apiPost } from './api';
import _ from 'lodash';

const actions = {
    form_get_data: createAction('FORM_GET_DATA'),
    form_succeeded: createAction('FORM_SUCCEEDED'),
    form_failed: createAction('FORM_FAILED'),

    vrm_search: createAction('VRM_SEARCH'),
    vrm_succeeded: createAction('VRM_SUCCEEDED'),
    vrm_failed: createAction('VRM_FAILED'),

    postcode_search: createAction('POSTCODE_SEARCH'),
    postcode_select: createAction('POSTCODE_SELECT'),
    postcode_search_succeeded: createAction('POSTCODE_SEARCH_SUCCEEDED'),
    postcode_select_succeeded: createAction('POSTCODE_SELECT_SUCCEEDED'),
    postcode_failed: createAction('POSTCODE_FAILED'),

    add: createAction('FROM_ADD_ITEM'),
    remove: createAction('FROM_REMOVE_ITEM'),

    company_search: createAction('COMPANY_SEARCH'),
    company_search_succeeded: createAction('COMPANY_SEARCH_SUCCEEDED'),
    company_search_failed: createAction('COMPANY_SELECT_FAILED')
};

export default actions;

const vrmSearch = (response, name, uuid, key) => ({
    type: actions.vrm_search.toString(),
    uuid,
    name,
    key,
    payload: response,
});
const vrmSucceeded = (response, name, uuid, key) => ({
    type: actions.vrm_succeeded.toString(),
    uuid,
    name,
    key,
    payload: response,
});
const vrmFailed = (response, name, uuid, key) => ({
    type: actions.vrm_failed.toString(),
    name,
    uuid,
    key,
    payload: response,
});

const postcodeSearch = (response, name, uuid, key) => ({
    type: actions.postcode_search.toString(),
    uuid,
    name,
    payload: response,
    key
});
const postcodeSelect = (response, name, uuid, key) => ({
    type: actions.postcode_select.toString(),
    uuid,
    name,
    payload: response,
    key
});
const postcodeSearchSucceeded = (response, name, uuid, key) => ({
    type: actions.postcode_search_succeeded.toString(),
    uuid,
    name,
    payload: response,
    key
});
const postcodeSelectSucceeded = (response, name, uuid, key) => ({
    type: actions.postcode_select_succeeded.toString(),
    uuid,
    name,
    payload: response,
    key
});
const postcodeFailed = (response, name, uuid, key) => ({
    type: actions.postcode_failed.toString(),
    name,
    uuid,
    payload: response,
    key
});


export const formGetData = (response, name, uuid) => ({
    type: actions.form_get_data.toString(),
    name,
    uuid,
    payload: response,
});

const formSucceeded = (response, name, uuid) => ({
    type: actions.form_succeeded.toString(),
    uuid,
    name,
    payload: response,
});
const formFailed = (response, name, uuid, values) => ({
    type: actions.form_failed.toString(),
    uuid,
    name,
    values,
    payload: response,
});

export const getFormData = (name, data) => apiGet(
    'form',
    data,
    (res) => formGetData(res, name, data.uuid),
    (res) => formSucceeded(res, name, data.uuid),
    (res) => formFailed(res, name, data.uuid)
);

export const submit = (name, uuid, policy_uuid, policy_type, form_values) => apiPost(
    'form',
    {
        ...form_values,
        uuid,
        policy_uuid,
        policy_type,
        product: name
    },
    (res) => formGetData(res, name, uuid),
    (res) => formSucceeded(res, name, uuid),
    (res) => formFailed(res, name, uuid, { ...form_values })
);

export const submitManualBC = (name, uuid, policy_uuid, policy_type, form_values) => apiPost(
    'admin/quote',
    {
        ...form_values,
        uuid,
        policy_uuid,
        policy_type,
        product: name
    },
    (res) => formGetData(res, name, uuid),
    (res) => formSucceeded(res, name, uuid),
    (res) => formFailed(res, name, uuid, { ...form_values })
);

export const searchVrm = (name, uuid, vrm) => {
    const key = _.keys(vrm)[0];

    return apiPost(
        'api/lookup/vehicle/cdl',
        { vrm: _.values(vrm)[0] },
        (res) => vrmSearch(res, name, uuid, key),
        (res) => vrmSucceeded(res, name, uuid, key),
        (data) => vrmFailed(data, name, uuid, key)
    );
};

export const searchPostcode = (name, uuid, postcode) => {
    const key = _.keys(postcode)[0];

    return apiPost(
        'api/lookup/address/search',
        { postcode: _.values(postcode)[0] },
        (res) => postcodeSearch(res, name, uuid, key),
        (res) => postcodeSearchSucceeded(res, name, uuid, key),
        (res) => postcodeFailed(res, name, uuid, key)
    );
};

export const selectPostcode = (name, uuid, id) => {
    const key = _.keys(id)[0];

    return apiPost(
        'api/lookup/address/select',
        { id: _.values(id)[0] },
        (res) => postcodeSelect(res, name, uuid, key),
        (res) => postcodeSelectSucceeded(res, name, uuid, key),
        (res) => postcodeFailed(res, name, uuid, key)
    );
};

export const addField = (name, field) => ({
    type: actions.add.toString(),
    name,
    payload: field,
});
export const removeField = (name, field) => ({
    type: actions.remove.toString(),
    name,
    payload: field,
});

export const searchCompany = (name, uuid, companyNumber) => {
    const key = _.keys(companyNumber)[0];

    return apiGet(
        'api/company/nature-of-business',
        { company_number: _.values(companyNumber)[0] },
        (res) => companySearch(res, name, uuid, key),
        (res) => companySearchSucceeded(res, name, uuid, key),
        (res) => companySearchFailed(res, name, uuid, key)
    );
};

const companySearch = (response, name, uuid, key) => ({
    type: actions.company_search.toString(),
    uuid,
    name,
    payload: response,
    key
});

const companySearchSucceeded = (response, name, uuid, key) => ({
    type: actions.company_search_succeeded.toString(),
    uuid,
    name,
    payload: response,
    key
});
const companySearchFailed = (response, name, uuid, key) => ({
    type: actions.company_search_failed.toString(),
    name,
    uuid,
    payload: response,
    key
});
