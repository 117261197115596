import {handleActions} from 'redux-actions';
import actions from '../actions/policy';
import api_actions from '../actions/api';
import _ from 'lodash';
import redirect from '../../utils/redirect';
import config from '../../config';
import moment from 'moment';
import products from '../../utils/products';

function getDefaults() {
  return {
    isLoading: false,
    errors: {},
    details: {},
    policies: [],
    awaitingConfirmation: [],
    awaitingCancellation: [],
    message: '',
    redirect_path: '',
    cancellationBalance: {},
  };
}

export default handleActions({
  //policy
  [actions.policy_send]: (state, action) => {
    switch (action.payload) {
      case api_actions.api_start:
        return {
          ...state,
          errors: {},
          isLoading: true,
          policies: [],
        };
      case api_actions.api_end:
        return {
          ...state,
          isLoading: false,
        };
      default:
        return state;
    }
  },
  [actions.policy_refresh]: (state, action) => {
    switch (action.payload) {
      case api_actions.api_start:
        return {
          ...state,
          errors: {},
          isLoading: false,
        };
      case api_actions.api_end:
        return {
          ...state,
          isLoading: false,
        };
      default:
        return state;
    }
  },
  [actions.policy_succeeded]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [actions.policy_failed]: (state, action) => {
    const errors = _.get(action, 'payload.data.errors');

    return {
      ...state,
      errors: !_.isEmpty(errors) ? errors : (_.get(action, 'payload.data.message') ? _.get(action, 'payload.data.message') : 'Server Error, please try later'),
    };
  },
  //awaiting
  [actions.awaiting_send]: (state, action) => {
    switch (action.payload) {
      case api_actions.api_start:
        return {
          ...state,
          errors: {},
          isLoading: true,
          awaitingConfirmation: [],
          awaitingCancellation: [],
        };
      case api_actions.api_end:
        return {
          ...state,
          isLoading: false,
        };
      default:
        return state;
    }
  },
  [actions.awaiting_succeeded]: (state, action) => {
    const {awaiting_confirmation, awaiting_cancellation} = action.payload;

    const getAlerts = data => {
      const hours = moment.duration(moment().diff(moment(data.updatedAt))).asHours();
      if (hours > 5) {
        return {updatedAt: 'danger'};
      }
      if (hours > 2) {
        return {updatedAt: 'warning'};
      }
      return {};
    };

    return {
      ...state,
      awaitingConfirmation: awaiting_confirmation.map(d => ({...d, alerts: getAlerts(d)})),
      awaitingCancellation: awaiting_cancellation.map(d => ({...d, alerts: getAlerts(d)})),
    };
  },
  [actions.awaiting_failed]: (state, action) => {
    const errors = _.get(action, 'payload.data.errors');

    return {
      ...state,
      errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message'),
    };
  },

  [actions.details_send]: (state, action) => {
    switch (action.payload) {
      case api_actions.api_start:
        return {
          ...state,
          message: '',
          details: {},
          cancellation_balance: null,
          errors: {},
          isLoading: true,
        };
      case api_actions.api_end:
        return {
          ...state,
          isLoading: false,
        };
      default:
        return state;
    }
  },
  [actions.details_succeeded]: (state, action) => {
    const {message, ...rest} = action.payload;

    return {
      ...state,
      details: {
        ...state.details,
        ...rest,
      },
      message,
    };
  },
  [actions.details_failed]: (state, action) => {
    const errors = _.get(action, 'payload.data.errors');

    return {
      ...state,
      errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message'),
      details: state.details,
    };
  },

  //change policy state
  [actions.update_state_send]: (state, action) => {
    switch (action.payload) {
      case api_actions.api_start:
        return {
          ...state,
          message: '',
          errors: {},
          isLoading: true,
          redirect_path: '',
        };
      case api_actions.api_end:
        return {
          ...state,
          isLoading: false,
        };
      default:
        return state;
    }
  },
  [actions.update_state_succeeded]: (state, action) => {
    const {message, ...rest} = action.payload;
    const {lead_uuid, policy_uuid, product, type, renew = false} = _.pick(rest, [
      'lead_uuid',
      'policy_uuid',
      'product',
      'type',
      'renew',
    ]);

    const form = product === products.PRODUCT_MANUAL ? type : product;

    if (policy_uuid) {
      return {
        ...state,
        details: {
          ...state.details,
        },
        ...redirect.add(`/agent/${form.replace(/_/g, '-')}/${lead_uuid}/${policy_uuid}/${renew ? config.policy_types.POLICY_RENEWAL : config.policy_types.POLICY_MTA}`),
        message,
      };
    }

    return {
      ...state,
      details: {
        ...state.details,
        ...rest,
      },
      message,
      redirect_path: '',
    };
  },

  [actions.cancellation_balance_send]: (state, action) => {
    switch (action.payload) {
      case api_actions.api_start:
        return {
          ...state,
          cancellationBalance: {
            ...state.cancellationBalance,
            balance: null,
            isLoading: true,
            errors: null,
          },
        };
      case api_actions.api_end:
        return {
          ...state,
          cancellationBalance: {
            ...state.cancellationBalance,
            isLoading: false,
          },
        };
      default:
        return state;
    }
  },
  [actions.cancellation_balance_succeeded]: (state, action) => {
    const {cancellation_balance: balance} = action.payload;

    return {
      ...state,
      cancellationBalance: {
        ...state.cancellationBalance,
        balance,
      },
    };
  },
  [actions.cancellation_balance_failed]: (state, action) => ({
    ...state,
    cancellationBalance: {
      ...state.cancellationBalance,
      errors: action.payload.data?.message || 'Failed calculating the cancellation balance, please contact us',
    },
  }),
  [actions.cancellation_balance_reset]: (state) => ({
    ...state,
    cancellationBalance: getDefaults().cancellationBalance,
  }),
  [actions.update_state_failed]: (state, action) => {
    const message = _.get(action, 'payload.data.message');

    return {
      ...state,
      errors: message,
    };
  },
}, getDefaults());


