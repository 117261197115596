import {createStore, combineReducers, applyMiddleware} from 'redux';
import _ from 'lodash';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';

import fullFormActions from './actions/full.form';
import copyActions from './actions/copy';
import vehicleLookupActions from './actions/vehicle.lookup';

import products from '../utils/products';
import formReducer, {handleAddressLookupSearch} from './reducers/full.form';
import {handleCopyFailed, handleCopySucceeded} from './reducers/copy';
import listReducer from './reducers/lists';
import adminReducer from './reducers/admin';
import quoteReducer from './reducers/quote';
import policyReducer from './reducers/policy';
import vehicleLookupReducer, {handleUpdateVehicleDetailsManually} from './reducers/vehicle.lookup';

// import expireIn from './transform.expire.reducers';
import apiMiddleware from './middlewares/api.middleware';

const middlewares = [
    thunk,
    apiMiddleware
];


const getFullFormReducers = () => {
    let out = {};
    _.forEach(products, (v, k) => {
        out = {
            ...out,
            [v]: formReducer(v),
        };
    });

    return out;
};

const fullFormReducers = getFullFormReducers();

const combinedReducers = combineReducers({
    ...fullFormReducers,
    admin: adminReducer,
    lists: listReducer,
    quote: quoteReducer,
    policy: policyReducer,
    vehicleLookup: vehicleLookupReducer
});

const mixedReducers = (state, action) => {
    switch (action.type) {
    case fullFormActions.postcode_search().type:
        return handleAddressLookupSearch(state, action);
    case copyActions.copy_succeeded().type:
        return handleCopySucceeded(state, action);
    case copyActions.copy_failed().type:
        return handleCopyFailed(state, action);
    case vehicleLookupActions.update_vehicle_details_manually().type:
        return handleUpdateVehicleDetailsManually(state, action);
    default:
        return state;
    }
};
const rootReducers = (state, action) => mixedReducers(combinedReducers(state, action), action);

const persistedReducers = persistReducer(
    {
        key: 'first-underwriting-marketplace',
        storage,
        transforms: [], //todo step form
        whitelist: ['admin'], //todo step form
    },
    rootReducers,
);

function clearStorage () {
    //todo
}


const store = createStore(persistedReducers, {}, composeWithDevTools(applyMiddleware(...middlewares)));
const persistor = persistStore(store);
export {store, persistor, clearStorage};
