import {handleActions} from 'redux-actions';
import actions from '../actions/quote';
import api_actions from '../actions/api';
import _ from 'lodash';
import config from '../../config';
import redirect from '../../utils/redirect';

export const getDefaults = () => ({
    isLoading: false,
    overwriteRate: {
        isLoading: false,
        errors: {},
        message: '',
    },
    errors: {},
    quotes: [],
    details: {},
    message: ''
});


export default handleActions({
    [actions.get]: (state, action) => {

        switch (action.payload) {
            case api_actions.api_start:
                return {
                    ...state,
                    message: '',
                    errors: {},
                    isLoading: true
                };
            case api_actions.api_end:
                return {
                    ...state,
                    isLoading: false
                };
            default:
                return state;
        }
    },
    [actions.get_failed]: (state, action) => {
        const errors = _.get(action, 'payload.data.errors');

        return {
            ...state,
            errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message', '') || 'Server Error, please contact us'
        };
    },
    [actions.get_succeeded]: (state, action) => {

        const quotes = _.get(action, 'payload.quotes.quotes', []);

        quotes.forEach((q) => {
            q.quotes = _.orderBy(q.quotes, ['total_premium_inc_ipt_without_ncb'], ['asc']);

            return quotes;
        });

        const failReason = _.get(action, 'payload.fail_reason', '');
        const totalItems = _.get(action, 'payload.quotes.count', 0);
        const covers = _.get(action, 'payload.covers', []);
        const payment_types = _.get(action, 'payload.payment_types', []);
        const products = _.get(action, 'payload.products', []);
        const product = _.get(action, 'payload.product', []);
        const quote_state = _.get(action, 'payload.state', '');
        const risk = _.get(action, 'payload.risk', {});

        return {
            ...state,
            quotes,
            totalItems,
            covers,
            payment_types,
            quote_state,
            products,
            failReason,
            risk,
            product,
            copy: false
        };
    },

    [actions.details_send]: (state, action) => {
        switch (action.payload) {
            case api_actions.api_start:
                return {
                    ...state,
                    message: '',
                    details: {},
                    errors: {},
                    isLoading: true
                };
            case api_actions.api_end:
                return {
                    ...state,
                    isLoading: false
                };
            default:
                return state;
        }
    },
    [actions.details_succeeded]: (state, action) => {
        const {message, risk, quotes, mta_uuid, product, renewal_uuid} = action.payload;
        const type = _.get(quotes, '0.type', '');

        return {
            ...state,
            details: {
                ...state.details,
                risk,
                product,
                type,
                quote: quotes[0],
                mta_uuid,
                renewal_uuid
            },
            copy: false,
            message
        };
    },
    [actions.details_failed]: (state, action) => {
        const errors = _.get(action, 'payload.data.errors');

        return {
            ...state,
            errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message'),
            details: state.details
        };
    },

    //change quote state
    [actions.quote_update_state_send]: (state, action) => {
        switch (action.payload) {
            case api_actions.api_start:
                return {
                    ...state,
                    message: '',
                    errors: {},
                    isLoading: true
                };
            case api_actions.api_end:
                return {
                    ...state,
                    isLoading: false
                };
            default:
                return state;
        }
    },
    [actions.quote_update_state_succeeded]: (state, action) => {
        const {message, risk, quotes, policy_uuid} = action.payload;
        const quoteState = _.get(quotes, '0.state', '');
        if (quoteState === config.quote_states.STATE_USED && policy_uuid) {
            return {
                ...state,
                details: {
                    ...state.details,
                    risk,
                    quote: quotes[0],
                    ...redirect.add(`/agent/policy/details/${policy_uuid}`)
                },
                message
            };
        }

        return {
            ...state,
            details: {
                ...state.details,
                risk,
                quote: quotes[0]
            },
            message
        };
    },
    [actions.quote_update_state_failed]: (state, action) => {
        const message = _.get(action, 'payload.data.message');

        return {
            ...state,
            errors: message
        };
    },
    [actions.quote_update_rate_send]: (state, action) => {
        switch (action.payload) {
            case api_actions.api_start:
                return {
                    ...state,
                    overwriteRate: {
                        isLoading: true,
                        errors: {},
                        message: '',
                    },
                };
            case api_actions.api_end:
                return {
                    ...state,
                    overwriteRate: {
                        ...state.overwriteRate,
                        isLoading: false
                    },
                };
            default:
                return state;
        }
    },
    [actions.quote_update_rate_succeeded]: (state, action) => {
        const {message, risk, quotes} = action.payload;
        return {
            ...state,
            details: {
                ...state.details,
                risk,
                quote: quotes[0]
            },
            overwriteRate: {
                ...state.overwriteRate,
                message: 'Rate updated successfully'
            }
        };
    },
    [actions.quote_update_rate_failed]: (state, action) => {
        const errors = _.get(action, 'payload.data.errors', {});
        return {
            ...state,
            overwriteRate: {
                ...state.overwriteRate,
                errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message', '') || 'Server Error, please contact us'
            }
        };
    },
}, getDefaults());

