import products from './utils/products';

const STATE_NEW_LEAD = 'new_lead';
const STATE_IN_PROGRESS = 'in_progress';
const STATE_QUOTED = 'quoted';

const STATE_DECLINED = 'declined';
const STATE_FAILED = 'failed';
const STATE_USED = 'used';
const STATE_AWAITING_NEW_DOCUMENTS = 'awaiting_new_documents';
const STATE_AWAITING_CANCELLATION_DOCUMENTS = 'awaiting_cancellation_documents';
const STATE_AWAITING_CONFIRMATION = 'awaiting_confirmation';
const STATE_AWAITING_CANCELLATION = 'awaiting_cancellation';
const STATE_CANCELLED = 'cancelled';
const STATE_LIVE = 'live';
const STATE_PENDING_SCORE_CHECK = 'pending_score_check';
const STATE_OGI_CREDIT_QUOTED = 'ogi_credit_quoted';
const STATE_PENDING_CANCELLATION = 'pending_cancellation';
const STATE_OGI_RETRIEVED_SCORE_FROM_DB = 'ogi_retrieved_score_from_db';
const STATE_OGI_CREDIT_SENT_TO_BROKER = 'ogi_credit_sent_to_broker';

const STATE_LAPSED = 'lapsed'
const POLICY_NEW_BUSINESS = 'new_business';
const POLICY_MTA = 'mta';
const POLICY_RENEWAL = 'renewal';

const lead_states = {
    STATE_NEW_LEAD,
    STATE_IN_PROGRESS,
    STATE_QUOTED
};
const quote_states = {
    STATE_DECLINED,
    STATE_QUOTED,
    STATE_FAILED,
    STATE_USED,
    STATE_CANCELLED,
    STATE_PENDING_SCORE_CHECK,
    STATE_OGI_CREDIT_QUOTED,
    STATE_OGI_RETRIEVED_SCORE_FROM_DB,
    STATE_OGI_CREDIT_SENT_TO_BROKER
};

const policy_states = {
    STATE_AWAITING_NEW_DOCUMENTS,
    STATE_AWAITING_CANCELLATION_DOCUMENTS,
    STATE_CANCELLED,
    STATE_LIVE,
    STATE_DECLINED,
    STATE_AWAITING_CONFIRMATION,
    STATE_AWAITING_CANCELLATION,
    STATE_PENDING_CANCELLATION,
    STATE_LAPSED
};
const policy_types = {
    POLICY_NEW_BUSINESS,
    POLICY_MTA,
    POLICY_RENEWAL,
};

const isProduction = () => process.env.NODE_ENV === 'production';
export { isProduction };
console.log(process.env.NODE_ENV);
export default {
    backend_url: isProduction() ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL,
    lead_states,
    quote_states,
    policy_states,
    policy_types,
    [products.PRODUCT_FASTFOOD]: {
        max_drivers: 2,
        max_vehicles: 1,
        max_modifications: 5,
        max_security_devices: 5,
        max_occupations: 2,
        max_convictions: 5,
        max_incidents: 5,
        max_criminal_convictions: 5
    },
    [products.PRODUCT_BUSINESS_CAR]: {
        max_drivers: 5,
        max_vehicles: 1,
        max_modifications: 5,
        max_security_devices: 5,
        max_occupations: 2,
        max_convictions: 5,
        max_incidents: 5,
        max_criminal_convictions: 5,
        max_medical_conditions: 5
    },
    [products.PRODUCT_BC_ERS_ROLLOVER]: {
        max_drivers: 2,
        max_vehicles: 1,
        max_modifications: 5,
        max_security_devices: 5,
        max_occupations: 2,
        max_convictions: 5,
        max_incidents: 5,
        max_criminal_convictions: 5,
        max_medical_conditions: 5
    }
};
