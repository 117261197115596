import React, {Component} from 'react';
import _ from 'lodash';
import {
  Button,
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Jumbotron,
} from 'reactstrap';
import {formProducts, mapProducts} from '../../utils/products';
import {canAccess, isAuthorized, ROLES, SECTIONS} from '../../utils/user.utils';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import actions, {login} from '../../redux/actions/admin';
import AdminLoginForm from './form.login';

export default function(ComposedComponent, _props) {

  class PageAuthorisedTest extends Component {
    state = {
      openNav: false,
    };
    toggleNav = () => this.setState(prevState => ({...prevState, openNav: !prevState.openNav}));

    _renderNav() {
      const user = this.props.login;
      // filter access
      const canAccessProducts = canAccess(user, ROLES.USER, SECTIONS.PRODUCTS),
          canAccessQuotes = canAccess(user, ROLES.USER, SECTIONS.QUOTES),
          canAccessPolicies = canAccess(user, ROLES.USER, SECTIONS.POLICIES);

      const {onLogout, login: {email}} = this.props;
      const {openNav} = this.state;
      const {toggleNav} = this;

      const _products = () => _.map(formProducts, (v, k) => <DropdownItem key={k}>
        <NavLink href={`/#/agent/${v.replace(/_/g, '-')}`}>{mapProducts(v)}</NavLink>
      </DropdownItem>);

      return (
          <Navbar color="light" light expand="md">
            <NavbarBrand href="/">
              <img src='/first-logo.png' className='img-fluid' alt='logo'/>
              First Underwriting Portal
            </NavbarBrand>
            <NavbarToggler onClick={toggleNav}/>
            <Collapse isOpen={openNav} navbar>
              <Nav className="mr-auto" navbar>

                {canAccessProducts && (
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        Products
                      </DropdownToggle>
                      <DropdownMenu left="true">
                        {_products()}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                )}

                {canAccessQuotes && (
                    <UncontrolledDropdown nav inNavbar>
                      <NavLink href="/#/agent/quote">Quotes</NavLink>
                    </UncontrolledDropdown>
                )}

                {canAccessPolicies && (
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        Policies
                      </DropdownToggle>
                      <DropdownMenu left="true">
                        <DropdownItem>
                          <NavLink href="/#/agent/policy/live">Live</NavLink>
                        </DropdownItem>
                        <DropdownItem>
                          <NavLink href="/#/agent/policy/cancelled">Cancelled</NavLink>
                        </DropdownItem>
                        <DropdownItem>
                          <NavLink href="/#/agent/policy/awaiting">Awaiting</NavLink>
                        </DropdownItem>
                        <DropdownItem>
                          <NavLink href="/#/agent/policy/lapsed">Lapsed</NavLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                )}
              </Nav>
              <Button outline color="secondary" onClick={onLogout}>Logout ({email})</Button>
            </Collapse>
          </Navbar>

      );
    }

    _renderLogin() {
      const {login: {email, errors, success_message}, onLogin, login, onNavigate} = this.props;

      return (
          <Jumbotron>
            {!_.isEmpty(errors) && <div className="alert alert-danger text-center">{_.isString(errors) ? errors : 'Please fix the errors below'}</div>}
            {success_message.length > 0 && <div className="alert alert-success text-center">{success_message}</div>}
            <AdminLoginForm {...{onLogin, login, onNavigate}} initialValues={{email}}/>
          </Jumbotron>
      );
    }

    render() {
      if (!isAuthorized(this.props.login)) {
        return this._renderLogin();
      }
      //this class isn't meant to be rendered by itself
      return <main>
        {this._renderNav()}
        <div className={'container-fluid pt-5'}>
          <ComposedComponent {...this.props} user={this.props.login} userEmail={_.get(this.props, 'login.email')}/>
        </div>
      </main>;
    }
  }

  const mapStateToProps = (state) => {
    return {...state.admin, ..._props};

  };

  const mapDispatchToProps = (dispatch) => {
    const admin = bindActionCreators(actions, dispatch);
    return {
      onLogin: (data) => dispatch(login(data)),
      onLogout: () => dispatch(admin.logout),
      onNavigate: () => dispatch(admin.navigate),
    };
  };
  return connect(mapStateToProps, mapDispatchToProps)(PageAuthorisedTest);
}
