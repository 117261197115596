import {handleActions} from 'redux-actions';
import api_actions from '../actions/api';
import actions from '../actions/lists';
import _ from 'lodash';

function getDefaults () {
    return {
        isLoading: false,
        errors: {},
    };
}

export default handleActions({
    [actions.lists_get]: (state, action) => {
        switch (action.payload) {
        case api_actions.api_start:
            return {
                ...state,
                isLoading: true,
                errors: {}
            };
        case api_actions.api_end:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
        }
    },
    [actions.lists_succeeded]: (state, action) => {
        const {lists} = action.payload;

        return {...state,
            lists};
    },
    [actions.lists_failed]: (state, action) => {
        const errors = _.get(action, 'payload.data.error');

        return {
            ...state,
            errors: {errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message') || 'Impossible to get lists'}
        };
    }
}, getDefaults());


