import {createAction} from 'redux-actions';
import {apiGet, apiPost} from './api';

const actions = {
    get: createAction('GET_QUOTE'),
    get_failed: createAction('GET_QUOTE_FAILED'),
    get_succeeded: createAction('GET_QUOTE_SUCCEEDED'),

    details_send: createAction('QUOTE_DETAILS_SEND'),
    details_failed: createAction('QUOTE_DETAILS_FAILED'),
    details_succeeded: createAction('QUOTE_DETAILS_SUCCEEDED'),

    //action to update the quote state
    quote_update_state_send: createAction('QUOTE_STATE_SEND'),
    quote_update_state_succeeded: createAction('QUOTE_STATE_SEND_SUCCEEDED'),
    quote_update_state_failed: createAction('QUOTE_STATE_SEND_FAILED'),


    // actions to update rates
    quote_update_rate_send: createAction('QUOTE_UPDATE_RATE_SEND'),
    quote_update_rate_succeeded: createAction('QUOTE_UPDATE_RATE_SUCCEEDED'),
    quote_update_rate_failed: createAction('QUOTE_UPDATE_RATE_FAILED')
};
export default actions;

export const getQuotes = (data) => apiGet('quote', data, actions.get, actions.get_succeeded, actions.get_failed);
export const getQuoteDetails = (uuid) => apiGet('quote/details', uuid, actions.details_send, actions.details_succeeded, actions.details_failed);

export const onAccept = (data) => apiPost('quote/accept', data, actions.quote_update_state_send, actions.quote_update_state_succeeded, actions.quote_update_state_failed);
export const onDecline = (data) => apiPost('quote/decline', data, actions.quote_update_state_send, actions.quote_update_state_succeeded, actions.quote_update_state_failed);

export const updateRate = (data) => apiPost('quote/overwrite-rate', data, actions.quote_update_rate_send, actions.quote_update_rate_succeeded, actions.quote_update_rate_failed);
